import { FileType } from './attachment';
import { DxDataGridTableStoreData } from './general';

export enum ExternalPaymentProcessors {
  KLASHA = 'klasha',
  STRIPE = 'stripe',
  FLUTTERWAVE = 'flutterwave',
}

export enum InternalPaymentProcessors {
  OFFLINE = 'offline',
}

export enum WalletPaymentProcessors {
  KLASHA = 'klasha',
  STRIPE = 'stripe',
  FLUTTERWAVE = 'flutterwave',
  OFFLINE = 'offline',
}

export enum WalletTransactionUserTypes {
  TALENT = 'talent',
  COMPANYUSER = 'companyUser',
  SYSTEM = 'system',
}

export enum WalletWithdrawalTypes {
  CARD = 'card',
  BANK = 'bank',
}

export type WalletCardWithdrawalType = {
  id: string;
  type: WalletWithdrawalTypes.CARD;
  processor: ExternalPaymentProcessors;
  isVerified: boolean;
  [key: string]: unknown;
};

export type WalletBankWithdrawalType = {
  id: string;
  type: WalletWithdrawalTypes.BANK;
  processor?: ExternalPaymentProcessors;
  isVerified: boolean;
  bankName?: string;
  bankAccountNumber?: string;
  [key: string]: unknown;
};

export enum CardType {
  MASTERCARD = 'mastercard',
  VISA = 'visa',
  VERVE = 'verve',
  AMEX = 'amex',
  DINERS = 'diners',
  DISCOVER = 'discover',
  EFTPOS_AU = 'eftpos_au',
  JCB = 'jcb',
  UNIONPAY = 'unionpay',
  UNKNOWN = 'unknown',
}

export type WalletWithdrawalType =
  | WalletCardWithdrawalType
  | WalletBankWithdrawalType;

export enum WalletBalanceTypes {
  MAIN = 'main',
  DEBIT = 'debit',
  CREDIT = 'credit',
  ESCROW = 'escrow',
  ESCROW_PAID = 'escrow_paid',
  ESCROW_PENDING = 'escrow_pending',
  ESCROW_REFUNDED = 'escrow_refunded',
  TRANSFER_CREDIT_TOTAL = 'transfer_credit_total',
  TRANSFER_DEBIT_TOTAL = 'transfer_debit_total',
  FAILED_BILLS = 'failed_bills',
  PAID_BILLS = 'paid_bills',
  PENDING_BILLS = 'pending_bills',
  OFFLINE_FUNDING_SUCCESS = 'offline_funding_success',
  OFFLINE_FUNDING_PENDING = 'offline_funding_pending',
  OFFLINE_FUNDING_FAILED = 'offline_funding_failed',
  STRIPE_FUNDING_SUCCESS = 'stripe_funding_success',
  STRIPE_FUNDING_PENDING = 'stripe_funding_pending',
  STRIPE_FUNDING_FAILED = 'stripe_funding_failed',
  FLUTTERWAVE_FUNDING_SUCCESS = 'flutterwave_funding_success',
  FLUTTERWAVE_FUNDING_PENDING = 'flutterwave_funding_pending',
  FLUTTERWAVE_FUNDING_FAILED = 'flutterwave_funding_failed',
  KLASHA_FUNDING_SUCCESS = 'klasha_funding_success',
  KLASHA_FUNDING_PENDING = 'klasha_funding_pending',
  KLASHA_FUNDING_FAILED = 'klasha_funding_failed',
}

export enum WalletTransactionsReasons {
  WALLET_SUBSCRIPTION_CHARGE = 'wallet.subscription.charge',
  WALLET_TOPUP = 'wallet.topup',
  WALLET_PLATFORM_CHARGE = 'wallet.platform.charge',
  WALLET_TO_WALLET_TRANSFER = 'wallet.to.wallet.transfer',
  WALLET_ONEOFF_CHARGE = 'wallet.oneoff.charge',
  WALLET_WITHDRAWAL = 'wallet.withdrawal',
}

export enum WalletTransactionStatus {
  PENDING = 'pending',
  SUCCESS = 'success',
  FAILED = 'failed',
  CANCELED = 'canceled',
}

export type UserWalletDataType = {
  id: string;
  lastUpdateDate: Date;
  balances: Record<WalletBalanceTypes, string>;
};

export type WalletTxnType = {
  sn: number;
  id: string;
  createDate: string | Date;
  updateDate: string | Date;
  date: string | Date;
  amount: number | string;
  status: WalletTransactionStatus;
  processor: WalletPaymentProcessors;
  description: string;
  beneficiary: string;
  beneficiaryType: 'company' | 'talent';
  initiatedBy: string;
  processedBy: string;
  type: 'credit' | 'debit';
};

export type WalletTxnRespType =
  DxDataGridTableStoreData<WalletTxnType>;

export type PlatformBankAccountType = {
  sn: number;
  id: string;
  uniqueId: string;
  name: string;
  title: string;
  currency: {
    id: string;
    name: string;
    fullname: string;
  };
  country: {
    id: string;
    name: string;
  };
  accountNumber: string;
  sortCode: string;
  swiftCode: string;
  iban: string;
  createDate: string | Date;
  updateDate: string | Date;
  date: string | Date;
};

export type OfflineWalletTxnType = WalletTxnType & {
  platformBankTitle?: string;
  platformBankCurrency?: string;
  platformBankCountry?: string;
  oneUsdEquivalent: string | number;
  amountInLocalCurrency: string | number;
  paymentProof: FileType;
  platformBankAccount?: {
    bankAccountNumber?: string;
    bankName?: string;
    bankSortCode?: string;
    countryId: string | number;
    createdAt: string | Date;
    currencyId: string | number;
    iban?: string;
    id: string | number;
    swiftCode?: string | null;
    title: string;
    uniqueId: string;
    updatedAt: string | Date;
  };
  platformBankAccountCurrency?: PlatformBankAccountType['currency'];
};

export type OfflineWalletTxnRespType =
  DxDataGridTableStoreData<OfflineWalletTxnType>;

export type OfflinePaymentPlatformBankAccountType =
  PlatformBankAccountType & {
    oneUsDollarEquivalent: string | number;
  };

export type PlatformBankAccountRespType =
  DxDataGridTableStoreData<PlatformBankAccountType>;

export type OfflinePaymentPlatformBankAccountRespType =
  DxDataGridTableStoreData<OfflinePaymentPlatformBankAccountType>;

export type TalentTransferReceipientType = {
  id: string;
  uniqueId: string;
  talentProfileId: string;
  talentProfileUniqueId: string;
  name: string;
  email: string;
  phoneNumber: string;
  date: string | Date;
};

export type CompanyTransferReceipientType = {
  id: string;
  uniqueId: string;
  companyProfileId: string;
  name: string;
  adminNames: string;
  adminEmails: string;
  adminPhoneNumbers: string;
  date: string | Date;
};

export type TalentTransferReceipientRespType =
  DxDataGridTableStoreData<TalentTransferReceipientType>;

export type CompanyTransferReceipientRespType =
  DxDataGridTableStoreData<CompanyTransferReceipientType>;

export type TalentBalanceType = TalentTransferReceipientType & {
  balance: string | number;
  isVerified: boolean;
  isSuspended: boolean;
  talentProfileId: string;
};

export type TalentBalanceRespType =
  DxDataGridTableStoreData<TalentBalanceType>;

export type CompanyBalanceType = CompanyTransferReceipientType & {
  balance: string | number;
  isVerified: boolean;
  isSuspended: boolean;
  companyProfileId: string;
};

export type CompanyBalanceRespType =
  DxDataGridTableStoreData<CompanyBalanceType>;

export type WalletProfileMainData = {
  id: string;
  name: string;
  type: 'company' | 'talent';
};
